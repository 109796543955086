/**
 * FLEX-ROW -- ALIGNEMENT ELEMENTS
 */

.flex-row,
.flex-row-center,
.flex-row-right,
.flex-row-start,
.flex-row-space-evenly {
  flex-direction: row;
  display: flex
}

.flex-row {
  align-items: center
}

.flex-row-center {
  justify-content: center;
  align-items: center
}

.flex-row-right {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0 6px
}
.flex-row-space-evenly {
  justify-content: space-evenly
}

.flex-grow {
  flex-grow: 1;
}
/**
 * FLEX COLUMN -- ALIGNEMENT ELEMENTS
 */

.flex-column-normal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row,
.flex-row-center,
.flex-row-right .flex-column,
.flex-column-center {
  ion-icon {
    font-size: 24px;
  }

  .text-align-center {
    text-align: center;
  }
}
