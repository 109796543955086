body.mat-typography {


  *{
    font-family: Montserrat, sans-serif;
  }

  *[class^="material-icons"] {
    font-family: 'Material Icons', sans-serif;
  }

  .typo-60-white{
    font-weight: 300;
    font-size: 60px;
    line-height: 80px;
    color: var(--typo-color-white);
  }

  .typo-42-bold-white {
    font-weight: 300;
    font-size: 42px;
    line-height: 60px;
    color: var(--typo-color-white);
  }

  .typo-40-white {
    font-weight: 400;
    font-size: 40px;
    line-height: 46px;
    color: var(--typo-color-white);
  }

  .typo-40-bold-white {
    font-weight: 600;
    font-size: 40px;
    line-height: 46px;
    color: var(--typo-color-white);
  }

  .typo-38-bold {
    font-weight: 600;
    font-size: 38px;
    line-height: 50px;
    text-transform: uppercase;
  }

  .typo-38-white {
    font-weight: normal;
    font-size: 38px;
    line-height: 50px;
    color: var(--typo-color-white);
  }

  .typo-38-bold-white {
    font-weight: bold;
    font-size: 38px;
    line-height: 38px;
    color: var(--typo-color-white);
  }



  .typo-36-pro-yellow {
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    color: var(--mclu-pro-yellow);
  }


  .typo-36-medium-white {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: var(--typo-color-white);
  }

  .typo-36-montserrat {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: var(--mclu-green);
  }

  .typo-36-medium-yellow {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: var(--mclu-yellow);
  }



  .typo-36-medium-white {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: var(--typo-color-white);
  }
  .typo-34-medium-green {
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.02em;
    color: var(--mclu-green);
  }

  .typo-32-bold-yellow {
    font-weight: bold;
    font-size: 32px;
    line-height: 25px;
    color: var(--mclu-yellow);
  }

  .typo-32-yellow {
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    color: var(--mclu-yellow);
  }

  .typo-32-white {
    font-weight: 300;
    font-size: 32px;
    line-height: 39px;
    color: white;
  }

  .typo-30-white {
    font-weight: normal;
    font-size: 30px;
    line-height: 25px;
    color: var(--typo-color-white);
  }

  .typo-28-bold{
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
  }

  .typo-28-bold-green {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    color: var(--mclu-green);
  }
  .typo-28-bold-white {
    font-weight: 500;
    font-size: 28px;
    line-height: 34.13px;
    letter-spacing: 2px;
    color: var(--typo-color-white);
  }

  .typo-26-white {
    font-weight: 300;
    font-size: 26px;
    line-height: 30px;
    color: var(--typo-color-white);
  }

  .typo-24-grey-admin {
    font-weight: 600;
    font-size: 24px;
    line-height: 29.26px;
    color: var(--mclu-grey-admin);
  }

  .typo-24-grey {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: var(--mclu-grey);
  }

  .typo-24-green {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    color: var(--mclu-green);
  }

  .typo-24 {
    font-weight: 500;
    font-size: 24px;
    line-height: 12px;
    color: var(--mclu-grey);
  }

  .typo-24-medium-white {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: var(--typo-color-white);
  }

  .typo-24-white {
    font-weight: 600;
    font-size: 24px;
    line-height: 29.26px;
    color: var(--typo-color-white);
  }

  .typo-24-pro-yellow {
    font-weight: 300;
    font-size: 24px;
    line-height: 29.26px;
    color: var(--mclu-pro-yellow);
  }


  .typo-24-semibold {
    font-weight: 700;
    font-size: 24px;
    line-height: 12px;
    color: var(--typo-color-grey-dark);
  }

  .typo-24-semibold-green {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: var(--mclu-green);
  }

  .typo-24-semibold-blue {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: var(--mclu-blue);
  }

  .typo-24-bold-white {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: var(--typo-color-white);
  }

  .typo-24-bold-orange {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: var(--mclu-pro-yellow);
  }

  .typo-24-bold-pro-blue-underline {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-decoration: underline;
    color: var(--mclu-pro-blue);
  }



  .typo-23-yellow {
    font-weight: normal;
    font-size: 23px;
    line-height: 21px;
    color: var(--mclu-yellow);
  }

  .typo-21-medium-green {
    font-size: 21px;
    line-height: 25.6px;
    font-weight: 400;
    color: var(--mclu-green);
  }
  .typo-21 {
    font-weight: normal;
    font-size: 21px;
    line-height: 21px;
    color: var(--mclu-grey);
  }

  .typo-21-white {
    font-weight: normal;
    font-size: 21px;
    line-height: 21px;
    color: var(--typo-color-white);
  }

  .typo-15-white {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    color: var(--typo-color-white);
}

  .typo-21-bold-green {
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    color: var(--mclu-green);
  }

  .typo-21-bold-blue {
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    color: var(--mclu-blue);
  }

  .typo-21-bold-white {
    font-weight: bold;
    font-size: 21px;
    line-height: 32px;
    color: var(--typo-color-white);
  }

  .typo-21-medium-white {
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    color: var(--typo-color-white);
  }

  .typo-21-medium-pro-blue {
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    color: var(--mclu-pro-blue);
  }



  .typo-21-bold-black {
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    color: var(--mclu-grey);
  }

  .typo-20-semibold-green {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: var(--mclu-green);
  }

  .typo-20-semibold-red {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #FE0000;
  }

  .typo-20-bold-white {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: var(--typo-color-white);
  }

  .typo-20 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--mclu-grey);
  }


  .typo-20-green {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--mclu-green);
  }

  .typo-20-bold {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: var(--mclu-grey)
  }

  .typo-20-bold-green {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: var(--mclu-green);
  }

  .typo-20-bold-blue {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #3F7CE9;
  }

  .typo-20-bold-red {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FE0000;
  }

  .typo-20-bold-orange {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #F16823;
  }

  .typo-20-bold-pro-yellow{
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: var(--mclu-pro-yellow);
  }

  .typo-20-bold-pro-yellow-lite{
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
    color: var(--mclu-pro-yellow);
  }

  .typo-19-semibold-green {
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    color: var(--mclu-green);
  }

  .typo-18-bold-green {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: var(--mclu-green);
  }

  .typo-18-bold-black {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

 .typo-18-white {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--typo-color-white);
  }
  .typo-18-bold {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: var(--typo-color-grey-dark);
  }
  .typo-18-grey {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--mclu-grey)
  }

  .typo-18-grey-bold {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--mclu-grey)
  }

 .typo-18-medium-orange {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--mclu-pro-yellow);
  }

  .typo-18-medium-pro-blue {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--mclu-pro-blue);
  }

  .typo-18-semibold-white {
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--typo-color-white);
  }


  .typo-18-semibold-green-valid {
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--mclu-green-valid);
  }

  .typo-18-semibold-pro-yellow {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--mclu-pro-yellow);
  }

  .typo-18-semibold-pro-blue {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--mclu-pro-blue);
  }

  .typo-18-semibold-red {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--mclu-red);
  }


  .typo-18-semibold-green-valid {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--mclu-green-valid);
  }




  .typo-18-bold-white {
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: var(--typo-color-white);
  }

  .typo-18-extrabold-white {
    font-weight: 950;
    font-size: 18px;
    line-height: 22px;
    color: var(--typo-color-white);
  }

  .typo-18-blue {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--mclu-blue);
  }

  .typo-17 {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
  }

  .typo-17-white {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: var(--typo-color-white);
  }

  .typo-17-yellow {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: var(--mclu-yellow);
  }

  .typo-17-yellow-underline {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    text-decoration-line: underline;
    color: var(--mclu-yellow);
  }

  .typo-17-light {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 21px;
    color: var(--mclu-green);
  }


  .typo-16-bold {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-grey);
  }

  .typo-16-light {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-grey);
  }

  .typo-16-medium {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-grey);
  }

  .typo-16-white {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: var(--typo-color-white);
  }

  .typo-16-semibold {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-grey);
  }

  .typo-16-semibold-yellow {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-yellow);
  }

  .typo-16-semibold-green {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-green);
  }

  .typo-16-semibold-white {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--typo-color-white);
  }

  .typo-16-semibold-pro-blue {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-pro-blue);
  }

  .typo-16-semibold-pro-yellow {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-pro-yellow);
  }


  .typo-16-semibold-green-valid {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-green-valid);
  }

  .typo-16-bold-white {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--typo-color-white);
  }

  .typo-16-bold-yellow {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-yellow);
  }

  .typo-16-bold-green {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-green);
  }

  .typo-16-bold-blue {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-blue);
  }

  .typo-16-bold-green {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-green);
  }

  .typo-16-bold-red {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-red);
  }

  .typo-16-bold-disabled {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-disabled);
  }

  .typo-16-bold-pro-yellow {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-pro-yellow);
  }
  .typo-16-bold-pro-blue {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-pro-blue);
  }
  .typo-16-bold-green-valid {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-green-valid);
  }




  .typo-16-medium-blue {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--mclu-blue);
  }

  .typo-16 {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--typo-color-grey-dark);
  }

  .typo-15-bold-white {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: var(--typo-color-white);
  }

  .typo-15-blue {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: var(--mclu-blue);
  }

  .typo-14-bold {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: var(--typo-color-grey-dark);
  }

  .typo-14-bold-blue {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: var(--mclu-blue);
  }

  .typo-14-medium {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--mclu-grey);
  }

  .typo-14-semibold {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--mclu-grey);
  }

	.typo-14-grey {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--mclu-grey);
  }

  .typo-14-bold-white {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: var(--typo-color-white);
  }

  .typo-14-white {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--typo-color-white);
  }

  .typo-14 {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--mclu-grey);
  }

  .typo-14-bold-red {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: var(--mclu-red);
  }


  .typo-14-medium-white {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--typo-color-white);
  }

  .typo-14-pro-yellow {
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    color: var(--mclu-pro-yellow);
  }

  .typo-14-bold-green {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: var(--mclu-green);
  }

  .typo-14-blue {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--mclu-blue);
  }

  .typo-14-semibold-pro-blue {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--mclu-pro-blue);
  }


  .typo-14-semibold-blue {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
  }

  .typo-14-semibold-yellow {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--mclu-yellow);
  }


  .typo-14-green {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--mclu-green);
  }

  .typo-14-red {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--mclu-red);
  }

  .typo-14-medium-yellow {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--mclu-yellow);
  }



  .typo-14-semibold-white {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--typo-color-white);
  }

    .typo-14-bold-grey {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: var(--mclu-grey);
  }

  .typo-13-bold-yellow {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: var(--mclu-yellow);
  }

  .typo-13-bold-green {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: var(--mclu-green);
  }

  .typo-13-bold-red {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: var(--mclu-red);
  }

  .typo-13-medium {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: var(--mclu-grey);
  }

  .typo-13 {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: var(--mclu-grey);
  }

	.typo-12-semibold {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--mclu-grey);
  }

  .typo-12-semibold-yellow {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--mclu-yellow);
  }

  .typo-12-bold {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: var(--mclu-grey);
  }
  .typo-12-bold-inline{
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: var(--mclu-grey);
    display: inline;
  }

  .typo-12-medium {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--mclu-grey);
  }

  .typo-12-bold-red {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: var(--mclu-red);
  }
 .typo-12-semibold-white {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--typo-color-white);
  }

  .typo-12-bold-green {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: var(--mclu-green);
  }

  .typo-12-green {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--mclu-green);
  }

  .typo-12 {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: var(--mclu-grey);
  }

  .typo-12-red {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: var(--mclu-red);
  }

  .typo-12-orange {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--mclu-pro-yellow);
  }

  .typo-12-white {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: var(--typo-color-white);
  }

  .typo-10-medium-white {
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    color: var(--typo-color-white);
  }

  .typo-11 {
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    color: var(--mclu-grey);
  }

  .typo-11-white {
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: var(--typo-color-white);
  }

  .typo-11-bold-white {
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: var(--typo-color-white);
  }

  .typo-10-grey {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: var(--mclu-grey);
  }

  .typo-9-bold-white {
    font-weight: bold;
    font-size: 9px;
    line-height: 11px;
    color: var(--typo-color-white);
  }

  .typo-line-height-17 {
    line-height: 17px;

  }

  .mat-option-10{
    font-size: 11px;
    height: 30px;
  }


}
