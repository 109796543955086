
.mat-slider-thumb {
  background-color: var(--mclu-yellow) !important;
}

.mat-accent .mat-slider-track-fill {
  background-color: var(--mclu-yellow) !important;
}

.mat-slider-thumb-label {
  background-color: var(--mclu-yellow) !important;

}

tr.mat-header-row{
  height: 40px !important;
}

.mat-row{
  border-color: #455a64;
  border-width: 1px;
  border-style: solid;
}
.mat-row:nth-child(even){
  background-color: #D9D9D9;
}

.mat-row:nth-child(odd){
  background-color: white;
}

.mat-sort-header-arrow{
  color: #fff !important;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.ol-full-screen{
  right: 0px !important;
  top: 0px !important;
  position: absolute;
  background: rgba(217, 72, 54, 0.75) !important;

  button{
    color: black;
    background-color: white;
    width: 30px;
    height: 30px;
    font-size: 27px;
  }
}
