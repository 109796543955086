// CE FICHIER EST CONTIENT LE STYLE COMMUN DES CHAMPS DE SAISIE DE L'APPLICATION TOUT TYPE CONFONDUS

// ! ATTENTION !
// NE PAS METTRE L'ATTRIBUT WIDTH CAR IL VARIE EN FONCTION DES PAGES ET PLATEFORME

// :root pour appliquer la couleur sur la saisie
:root {
  ion-input, .mclu-select, .mclu-date {
    height: 30px;
    margin-bottom: 16px;
    line-height: 18px;
    color: var(--mclu-green);
    border: 1px solid var(--mclu-green);
    border-radius: 10px;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    font-size: 14px;
  }

  .mclu-select {
    padding-left: 10px;

    &:focus {
      outline: none;
    }
  }

  .ion-invalid,
  .ng-invalid,
  .ion-invalid:not(.ion-untouched),
  .ng-invalid:not(.ng-untouched),
  .mclu-select.ng-invalid {
    border-color: var(--mclu-red);
  }

  ion-item::part(native) {
    background-color: transparent;
  }

  ion-item > ion-input {
    background-color: white;
  }
}




