:root {

  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .etape-acd {
    margin-left: 40px;

    ul {
      list-style-type: none;
      padding-inline-start: 0;
    }

    .box-acd-item {
      background-color: var(--core-color-blue);
      color: #dceaff;
      height: 36px;
      width: 36px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 18px;
      flex-shrink: 0;
    }
  }

}
