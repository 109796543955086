@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Styles Typographies/Popover/Flexbox */
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
:root ion-input, :root .mclu-select, :root .mclu-date {
  height: 30px;
  margin-bottom: 16px;
  line-height: 18px;
  color: var(--mclu-green);
  border: 1px solid var(--mclu-green);
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 14px;
}
:root .mclu-select {
  padding-left: 10px;
}
:root .mclu-select:focus {
  outline: none;
}
:root .ion-invalid,
:root .ng-invalid,
:root .ion-invalid:not(.ion-untouched),
:root .ng-invalid:not(.ng-untouched),
:root .mclu-select.ng-invalid {
  border-color: var(--mclu-red);
}
:root ion-item::part(native) {
  background-color: transparent;
}
:root ion-item > ion-input {
  background-color: white;
}

body.mat-typography * {
  font-family: Montserrat, sans-serif;
}
body.mat-typography *[class^=material-icons] {
  font-family: "Material Icons", sans-serif;
}
body.mat-typography .typo-60-white {
  font-weight: 300;
  font-size: 60px;
  line-height: 80px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-42-bold-white {
  font-weight: 300;
  font-size: 42px;
  line-height: 60px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-40-white {
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-40-bold-white {
  font-weight: 600;
  font-size: 40px;
  line-height: 46px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-38-bold {
  font-weight: 600;
  font-size: 38px;
  line-height: 50px;
  text-transform: uppercase;
}
body.mat-typography .typo-38-white {
  font-weight: normal;
  font-size: 38px;
  line-height: 50px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-38-bold-white {
  font-weight: bold;
  font-size: 38px;
  line-height: 38px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-36-pro-yellow {
  font-weight: 300;
  font-size: 36px;
  line-height: 44px;
  color: var(--mclu-pro-yellow);
}
body.mat-typography .typo-36-medium-white {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-36-montserrat {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: var(--mclu-green);
}
body.mat-typography .typo-36-medium-yellow {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: var(--mclu-yellow);
}
body.mat-typography .typo-36-medium-white {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-34-medium-green {
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.02em;
  color: var(--mclu-green);
}
body.mat-typography .typo-32-bold-yellow {
  font-weight: bold;
  font-size: 32px;
  line-height: 25px;
  color: var(--mclu-yellow);
}
body.mat-typography .typo-32-yellow {
  font-weight: 400;
  font-size: 32px;
  line-height: 41px;
  color: var(--mclu-yellow);
}
body.mat-typography .typo-32-white {
  font-weight: 300;
  font-size: 32px;
  line-height: 39px;
  color: white;
}
body.mat-typography .typo-30-white {
  font-weight: normal;
  font-size: 30px;
  line-height: 25px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-28-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
}
body.mat-typography .typo-28-bold-green {
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: var(--mclu-green);
}
body.mat-typography .typo-28-bold-white {
  font-weight: 500;
  font-size: 28px;
  line-height: 34.13px;
  letter-spacing: 2px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-26-white {
  font-weight: 300;
  font-size: 26px;
  line-height: 30px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-24-grey-admin {
  font-weight: 600;
  font-size: 24px;
  line-height: 29.26px;
  color: var(--mclu-grey-admin);
}
body.mat-typography .typo-24-grey {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-24-green {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: var(--mclu-green);
}
body.mat-typography .typo-24 {
  font-weight: 500;
  font-size: 24px;
  line-height: 12px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-24-medium-white {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-24-white {
  font-weight: 600;
  font-size: 24px;
  line-height: 29.26px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-24-pro-yellow {
  font-weight: 300;
  font-size: 24px;
  line-height: 29.26px;
  color: var(--mclu-pro-yellow);
}
body.mat-typography .typo-24-semibold {
  font-weight: 700;
  font-size: 24px;
  line-height: 12px;
  color: var(--typo-color-grey-dark);
}
body.mat-typography .typo-24-semibold-green {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: var(--mclu-green);
}
body.mat-typography .typo-24-semibold-blue {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: var(--mclu-blue);
}
body.mat-typography .typo-24-bold-white {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-24-bold-orange {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: var(--mclu-pro-yellow);
}
body.mat-typography .typo-24-bold-pro-blue-underline {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-decoration: underline;
  color: var(--mclu-pro-blue);
}
body.mat-typography .typo-23-yellow {
  font-weight: normal;
  font-size: 23px;
  line-height: 21px;
  color: var(--mclu-yellow);
}
body.mat-typography .typo-21-medium-green {
  font-size: 21px;
  line-height: 25.6px;
  font-weight: 400;
  color: var(--mclu-green);
}
body.mat-typography .typo-21 {
  font-weight: normal;
  font-size: 21px;
  line-height: 21px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-21-white {
  font-weight: normal;
  font-size: 21px;
  line-height: 21px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-15-white {
  font-family: Montserrat, sans-serif;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-21-bold-green {
  font-weight: bold;
  font-size: 21px;
  line-height: 26px;
  color: var(--mclu-green);
}
body.mat-typography .typo-21-bold-blue {
  font-weight: bold;
  font-size: 21px;
  line-height: 26px;
  color: var(--mclu-blue);
}
body.mat-typography .typo-21-bold-white {
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-21-medium-white {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-21-medium-pro-blue {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: var(--mclu-pro-blue);
}
body.mat-typography .typo-21-bold-black {
  font-weight: bold;
  font-size: 21px;
  line-height: 26px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-20-semibold-green {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: var(--mclu-green);
}
body.mat-typography .typo-20-semibold-red {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #FE0000;
}
body.mat-typography .typo-20-bold-white {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-20 {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-20-green {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--mclu-green);
}
body.mat-typography .typo-20-bold {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-20-bold-green {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--mclu-green);
}
body.mat-typography .typo-20-bold-blue {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #3F7CE9;
}
body.mat-typography .typo-20-bold-red {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #FE0000;
}
body.mat-typography .typo-20-bold-orange {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #F16823;
}
body.mat-typography .typo-20-bold-pro-yellow {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--mclu-pro-yellow);
}
body.mat-typography .typo-20-bold-pro-yellow-lite {
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  color: var(--mclu-pro-yellow);
}
body.mat-typography .typo-19-semibold-green {
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  color: var(--mclu-green);
}
body.mat-typography .typo-18-bold-green {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: var(--mclu-green);
}
body.mat-typography .typo-18-bold-black {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
body.mat-typography .typo-18-white {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-18-bold {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--typo-color-grey-dark);
}
body.mat-typography .typo-18-grey {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-18-grey-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-18-medium-orange {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--mclu-pro-yellow);
}
body.mat-typography .typo-18-medium-pro-blue {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--mclu-pro-blue);
}
body.mat-typography .typo-18-semibold-white {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-18-semibold-green-valid {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--mclu-green-valid);
}
body.mat-typography .typo-18-semibold-pro-yellow {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--mclu-pro-yellow);
}
body.mat-typography .typo-18-semibold-pro-blue {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--mclu-pro-blue);
}
body.mat-typography .typo-18-semibold-red {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--mclu-red);
}
body.mat-typography .typo-18-semibold-green-valid {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--mclu-green-valid);
}
body.mat-typography .typo-18-bold-white {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-18-extrabold-white {
  font-weight: 950;
  font-size: 18px;
  line-height: 22px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-18-blue {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: var(--mclu-blue);
}
body.mat-typography .typo-17 {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
}
body.mat-typography .typo-17-white {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-17-yellow {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: var(--mclu-yellow);
}
body.mat-typography .typo-17-yellow-underline {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  text-decoration-line: underline;
  color: var(--mclu-yellow);
}
body.mat-typography .typo-17-light {
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 21px;
  color: var(--mclu-green);
}
body.mat-typography .typo-16-bold {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-16-light {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-16-medium {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-16-white {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-16-semibold {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-16-semibold-yellow {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-yellow);
}
body.mat-typography .typo-16-semibold-green {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-green);
}
body.mat-typography .typo-16-semibold-white {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-16-semibold-pro-blue {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-pro-blue);
}
body.mat-typography .typo-16-semibold-pro-yellow {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-pro-yellow);
}
body.mat-typography .typo-16-semibold-green-valid {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-green-valid);
}
body.mat-typography .typo-16-bold-white {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-16-bold-yellow {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-yellow);
}
body.mat-typography .typo-16-bold-green {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-green);
}
body.mat-typography .typo-16-bold-blue {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-blue);
}
body.mat-typography .typo-16-bold-green {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-green);
}
body.mat-typography .typo-16-bold-red {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-red);
}
body.mat-typography .typo-16-bold-disabled {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-disabled);
}
body.mat-typography .typo-16-bold-pro-yellow {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-pro-yellow);
}
body.mat-typography .typo-16-bold-pro-blue {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-pro-blue);
}
body.mat-typography .typo-16-bold-green-valid {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-green-valid);
}
body.mat-typography .typo-16-medium-blue {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--mclu-blue);
}
body.mat-typography .typo-16 {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--typo-color-grey-dark);
}
body.mat-typography .typo-15-bold-white {
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-15-blue {
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: var(--mclu-blue);
}
body.mat-typography .typo-14-bold {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: var(--typo-color-grey-dark);
}
body.mat-typography .typo-14-bold-blue {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: var(--mclu-blue);
}
body.mat-typography .typo-14-medium {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-14-semibold {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-14-grey {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-14-bold-white {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-14-white {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-14 {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-14-bold-red {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--mclu-red);
}
body.mat-typography .typo-14-medium-white {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-14-pro-yellow {
  font-weight: 600;
  font-size: 14px;
  line-height: 17.07px;
  color: var(--mclu-pro-yellow);
}
body.mat-typography .typo-14-bold-green {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: var(--mclu-green);
}
body.mat-typography .typo-14-blue {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--mclu-blue);
}
body.mat-typography .typo-14-semibold-pro-blue {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--mclu-pro-blue);
}
body.mat-typography .typo-14-semibold-blue {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;
}
body.mat-typography .typo-14-semibold-yellow {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--mclu-yellow);
}
body.mat-typography .typo-14-green {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--mclu-green);
}
body.mat-typography .typo-14-red {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--mclu-red);
}
body.mat-typography .typo-14-medium-yellow {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--mclu-yellow);
}
body.mat-typography .typo-14-semibold-white {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-14-bold-grey {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-13-bold-yellow {
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: var(--mclu-yellow);
}
body.mat-typography .typo-13-bold-green {
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: var(--mclu-green);
}
body.mat-typography .typo-13-bold-red {
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: var(--mclu-red);
}
body.mat-typography .typo-13-medium {
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-13 {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-12-semibold {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-12-semibold-yellow {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--mclu-yellow);
}
body.mat-typography .typo-12-bold {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-12-bold-inline {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: var(--mclu-grey);
  display: inline;
}
body.mat-typography .typo-12-medium {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-12-bold-red {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: var(--mclu-red);
}
body.mat-typography .typo-12-semibold-white {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-12-bold-green {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: var(--mclu-green);
}
body.mat-typography .typo-12-green {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--mclu-green);
}
body.mat-typography .typo-12 {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-12-red {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: var(--mclu-red);
}
body.mat-typography .typo-12-orange {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--mclu-pro-yellow);
}
body.mat-typography .typo-12-white {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-10-medium-white {
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-11 {
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-11-white {
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-11-bold-white {
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-10-grey {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--mclu-grey);
}
body.mat-typography .typo-9-bold-white {
  font-weight: bold;
  font-size: 9px;
  line-height: 11px;
  color: var(--typo-color-white);
}
body.mat-typography .typo-line-height-17 {
  line-height: 17px;
}
body.mat-typography .mat-option-10 {
  font-size: 11px;
  height: 30px;
}

.popover-detail-particulier::part(content) {
  position: initial;
  min-width: 848px;
}

.ios ion-popover {
  --width: 97%;
}
.ios ion-popover mclu-popover-content {
  margin: 0 auto;
  width: 90%;
  max-width: 800px;
  border-radius: 10px;
}

@media (min-width: 461px) {
  mclu-popover-content {
    margin: 0 auto;
    width: 90%;
    max-width: 800px;
    border-radius: 10px;
  }
}
ion-popover {
  --width: 90%;
  --background: transparent;
  --box-shadow: none;
  color: var(--core-color-grey-dark);
}
ion-popover mclu-popover-content {
  padding: 12px;
  background-color: white;
  border: 8px solid var(--mclu-green);
}
ion-popover mclu-popover-content .flex-row-center img {
  margin-right: 6px;
  width: 80px;
}
ion-popover mclu-popover-content .flex-row-center p {
  text-align: center;
  white-space: pre-line;
}
ion-popover mclu-popover-content .buttons {
  text-align: center;
}
ion-popover mclu-popover-creation-compte {
  border: 2px solid var(--mclu-green);
}
@media (min-width: 461px) {
  ion-popover mclu-popover-creation-compte {
    width: 60%;
    margin: 0 auto;
  }
}
ion-popover .popover-creation-compte-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: var(--mclu-green);
}
ion-popover .popover-creation-compte-header ion-icon {
  color: var(--core-color-white);
  font-size: 28px;
}
ion-popover .popover-creation-compte-content {
  padding: 16px;
  background-color: var(--core-color-white);
}
ion-popover .popover-creation-compte-content .buttons {
  display: flex;
  justify-content: center;
}

:root {
  /**
   * Override Popup de chargement -------------------
   */
}
@media (prefers-color-scheme: dark) {
  :root .mclu-page-content {
    --ion-background-color: #000;
  }
}
:root .popover-erreur-creation-compte-content {
  margin-left: auto;
  margin-right: auto;
}
:root .popover-erreur-creation-compte-content .buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
:root .popover-send-code, :root .popover-password, :root .popover-erreur-creation-compte-content {
  padding: 16px;
  margin: auto;
  max-width: 50%;
  background-color: var(--core-color-white);
  border-radius: 10px;
  border: #276652 solid 4px;
}
:root .popover-send-code p, :root .popover-password p, :root .popover-erreur-creation-compte-content p {
  margin: 0;
  text-align: center;
}
:root .popover-erreur-connexion {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: fit-content;
  margin: auto;
  background: white;
  border: 10px solid var(--mclu-yellow);
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
}
@media screen and (max-width: 720px) {
  :root .popover-send-code, :root .popover-password, :root .popover-erreur-creation-compte-content {
    max-width: 100%;
  }
  :root .popover-generic-mobile {
    min-width: 0 !important;
  }
}
:root .spinner-custom-class {
  --background: #fff;
  --spinner-color: var(--mclu-green);
  color: var(--mclu-green);
}

.updateConfirmAlert .alert-wrapper {
  margin: auto;
  min-width: 25%;
  text-align: center;
  border-radius: 10px;
  border: #276652 solid 10px;
}
.updateConfirmAlert .alert-wrapper p {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: var(--mclu-green);
  text-transform: uppercase;
}
.updateConfirmAlert .alert-wrapper .alert-button-group {
  display: flex;
  justify-content: center;
}
.updateConfirmAlert .alert-wrapper .alert-button-group .alert-button {
  margin: 0;
  color: white;
  background-color: var(--mclu-grey);
  border-radius: 8px;
}
.updateConfirmAlert .alert-wrapper .alert-button-group .alert-button span {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
}

/**
 * FLEX-ROW -- ALIGNEMENT ELEMENTS
 */
.flex-row,
.flex-row-center,
.flex-row-right,
.flex-row-start,
.flex-row-space-evenly {
  flex-direction: row;
  display: flex;
}

.flex-row {
  align-items: center;
}

.flex-row-center {
  justify-content: center;
  align-items: center;
}

.flex-row-right {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0 6px;
}

.flex-row-space-evenly {
  justify-content: space-evenly;
}

.flex-grow {
  flex-grow: 1;
}

/**
 * FLEX COLUMN -- ALIGNEMENT ELEMENTS
 */
.flex-column-normal {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row ion-icon,
.flex-row-center ion-icon,
.flex-row-right .flex-column ion-icon,
.flex-column-center ion-icon {
  font-size: 24px;
}
.flex-row .text-align-center,
.flex-row-center .text-align-center,
.flex-row-right .flex-column .text-align-center,
.flex-column-center .text-align-center {
  text-align: center;
}

.mat-slider-thumb {
  background-color: var(--mclu-yellow) !important;
}

.mat-accent .mat-slider-track-fill {
  background-color: var(--mclu-yellow) !important;
}

.mat-slider-thumb-label {
  background-color: var(--mclu-yellow) !important;
}

tr.mat-header-row {
  height: 40px !important;
}

.mat-row {
  border-color: #455a64;
  border-width: 1px;
  border-style: solid;
}

.mat-row:nth-child(even) {
  background-color: #D9D9D9;
}

.mat-row:nth-child(odd) {
  background-color: white;
}

.mat-sort-header-arrow {
  color: #fff !important;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
}

.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}

.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.ol-full-screen {
  right: 0px !important;
  top: 0px !important;
  position: absolute;
  background: rgba(217, 72, 54, 0.75) !important;
}
.ol-full-screen button {
  color: black;
  background-color: white;
  width: 30px;
  height: 30px;
  font-size: 27px;
}

/* Core CSS required for Ionic components to work properly */
/* Basic CSS for apps built with Ionic */
/* Optional CSS utils that can be commented out */
:root .flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
:root .etape-acd {
  margin-left: 40px;
}
:root .etape-acd ul {
  list-style-type: none;
  padding-inline-start: 0;
}
:root .etape-acd .box-acd-item {
  background-color: var(--core-color-blue);
  color: #dceaff;
  height: 36px;
  width: 36px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  flex-shrink: 0;
}

/* FONT */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  src: url(assets/fonts/Montserrat-VariableFont_wght.ttf);
}
.cursor-pointer {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

/* Utilisé pour surcharger les pages router-outlet enfant
(Ne pas utiliser sur le router-outlet de app.component.html) */
.content-subpage {
  margin-top: 24px;
  margin-left: 15px;
  margin-right: 15px;
}
.content-subpage .ion-page {
  justify-content: flex-start !important;
}

/* Généralement, le CSS pour le mode desktop */
.page-padding {
  --padding-start: calc((100% - 1200px) / 2);
  --padding-end: calc((100% - 1200px) / 2);
}

/* Media query pour les écrans de moins de 500px (typiquement les téléphones) */
@media (max-width: 500px) {
  .page-padding {
    --padding-top: 15px;
    --padding-start: 15px;
    --padding-end: 15px;
  }
}
/* Media query pour exclure les tablettes des styles mobiles */
@media (min-width: 501px) and (max-width: 1024px) {
  .page-padding {
    --padding-top: calc((100% - 1200px) / 2);
    --padding-start: calc((100% - 1200px) / 2);
    --padding-end: calc((100% - 1200px) / 2);
  }
}
/**
 * BUTTON - OVERRIDE
 */
ion-button {
  text-transform: none;
  --box-shadow: none;
  --border-radius: 6px;
}
ion-button.button-solid {
  --background: var(--mclu-grey);
}

/**
 * MODAL
 */
ion-modal {
  --background: auto;
  --border-radius: 30px 30px 0 0;
}
ion-modal ion-content {
  --background: var(--core-color-white);
  --padding-top: 18px;
  --padding-bottom: 12px;
  --padding-start: 12px;
  --padding-end: 12px;
}

/**
 * Override IOS platform
 */
.plt-mobile.ios .cdk-overlay-pane {
  max-height: 210px;
}

.text-align-left {
  text-align: left;
}

.backround-blue {
  background-color: #0063A6;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 20px;
}

ion-input[aria-disabled=true] {
  border-color: black;
}

.native-input[disabled].sc-ion-input-md {
  opacity: 1;
  color: black;
}

select:disabled, ion-select:disabled {
  color: black !important;
  background: white !important;
  opacity: 1;
  pointer-events: none !important;
  border-color: black !important;
}

.lecture {
  pointer-events: none;
}

.lecture > * {
  color: black !important;
}
.lecture > * mclu-input-autocomplete > form > input.mclu-select {
  color: black;
  border-color: black;
}
.lecture > * mclu-input-autocomplete > form > .material-icons {
  display: none;
}
.lecture > * div {
  color: black !important;
}
.lecture > * select, .lecture > * ion-select {
  color: black !important;
  border-color: black !important;
  background: white !important;
}
.lecture > * ion-input {
  color: black !important;
  border-color: black !important;
}
.lecture > * ion-label {
  color: black !important;
}
.lecture > * ion-radio {
  --color-checked: black !important;
  text-decoration-color: black !important;
  --color: black !important;
}
.lecture > * .item-number {
  background-color: black !important;
}
.lecture > * u {
  text-decoration-color: black !important;
}
.lecture > * .underline {
  border-top-color: black !important;
}

.require_star {
  color: red;
}

.hand {
  cursor: pointer;
}

.pageContainer, .pageContainerFull {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: auto;
}
.pageContainer .page, .pageContainerFull .page {
  flex: 1;
  display: flex;
}
.pageContainer .page .content-container, .pageContainerFull .page .content-container {
  flex: 1;
}
.pageContainer .page .content-container .header, .pageContainerFull .page .content-container .header {
  text-align: left;
  display: flex;
  flex-direction: row;
}
.pageContainer mclu-footer, .pageContainerFull mclu-footer {
  flex-shrink: 0;
  margin-top: auto;
}

.pageContainerFull .page {
  width: 100vw;
  overflow-x: auto;
}

.templateDesktop .page {
  overflow-x: auto;
}
.templateDesktop .header {
  align-items: center;
}
.templateDesktop .header .icone {
  width: 70px;
  height: 70px;
}

.templateMobile .content-container {
  background: url("./assets/png/fond_mobile.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
}
.templateMobile .content-container .header .title {
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  flex: 1;
  padding: 8px;
}
.templateMobile .content-container .header .icone {
  font-size: 48px;
  margin: 8px;
}

.templateMobile .close, .isMobile .close {
  font-size: 20px;
  margin: 2px;
  color: white;
}

body.scanner-active, body.scanner-active * {
  --background: transparent;
  --ion-background-color: transparent;
  background: none;
}

.map .panel {
  z-index: 2;
  position: absolute;
  width: 330px;
  max-width: calc(100vw - 64px);
  max-height: calc(100% - 12px);
  background-color: white;
  border-radius: 5px;
  border: 1px solid black;
  overflow: auto;
}
.map .panel > .content {
  padding: 10px 16px;
}
.map .panel.center {
  top: 50%;
  left: calc(50% - 25px);
  transform: translate(-50%, -50%);
}
.map .panel.right {
  top: 50%;
  right: 62px;
  transform: translateY(-50%);
}
.map .panel > .btns {
  padding-right: 16px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.map .panel > .btns > * {
  flex-grow: 1;
  margin-left: 16px;
}

html, body {
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.menu {
  background-color: white;
}