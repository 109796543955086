.popover-detail-particulier::part(content) {
  position: initial;
  min-width: 848px;
}


// === FIX IOS ===
.ios {
  ion-popover {
    --width: 97%;

    mclu-popover-content {
      margin: 0 auto;
      width: 90%;
      max-width: 800px;
      border-radius: 10px;
    }
  }
}

// @override popover desktop
@media (min-width: 461px) {
  mclu-popover-content {
    margin: 0 auto;
    width: 90%;
    max-width: 800px;
    border-radius: 10px;
  }
}

ion-popover {
  --width: 90%;
  --background: transparent;
  --box-shadow: none;
  color: var(--core-color-grey-dark);

  mclu-popover-content {
    padding: 12px;
    background-color: white;
    border: 8px solid var(--mclu-green);

    .flex-row-center {
      img {
        margin-right: 6px;
        width: 80px;
      }

      p {
        text-align: center;
        white-space: pre-line;
      }
    }

    .buttons {
      text-align: center;
    }
  }

  // POP OVER CONFIRMATION DE CRÉATION DE COMPTE ====================
  mclu-popover-creation-compte {
    border: 2px solid var(--mclu-green)
  }

  // @override popover desktop
  @media (min-width: 461px) {
    mclu-popover-creation-compte {
      width: 60%;
      margin: 0 auto;
    }
  }

  .popover-creation-compte-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: var(--mclu-green);

    ion-icon {
      color: var(--core-color-white);
      font-size: 28px;
    }
  }

  .popover-creation-compte-content {
    padding: 16px;
    background-color: var(--core-color-white);

    .buttons {
      display: flex;
      justify-content: center;
    }
  }
}

:root {
  @media (prefers-color-scheme: dark) {
    .mclu-page-content {
      --ion-background-color: #000;
    }
  }

  .popover-erreur-creation-compte-content {
    margin-left: auto;
    margin-right: auto;

    .buttons {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }

  .popover-send-code, .popover-password, .popover-erreur-creation-compte-content {
    padding: 16px;
    margin: auto;
    max-width: 50%;
    background-color: var(--core-color-white);
    border-radius: 10px;
    border: #276652 solid 4px;

    p {
      margin: 0;
      text-align: center;
    }
  }

  .popover-erreur-connexion {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: fit-content;
    margin: auto;
    background: white;
    border: 10px solid var(--mclu-yellow);
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
  }

  @media screen and (max-width: 720px) {
    .popover-send-code, .popover-password, .popover-erreur-creation-compte-content {
      max-width: 100%;
    }
    .popover-generic-mobile {
      min-width: 0 !important;
    }
  }

  /**
   * Override Popup de chargement -------------------
   */
  .spinner-custom-class {
    --background: #fff;
    --spinner-color: var(--mclu-green);

    color: var(--mclu-green);
  }
}


// ---------- ALERT ----------
.updateConfirmAlert .alert-wrapper {
  margin: auto;
  min-width: 25%;
  text-align: center;
  border-radius: 10px;
  border: #276652 solid 10px;

  p {
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: var(--mclu-green);
    text-transform: uppercase;
  }

  .alert-button-group {
    display: flex;
    justify-content: center;

    .alert-button {
      margin: 0;
      color: white;
      background-color: var(--mclu-grey);
      border-radius: 8px;

      span {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 21px;
      }
    }
  }
}
