// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  /*====== NOUVEAU DESIGN ======*/

  /*==== COULEURS APPLICATIONS ====*/
  --mclu-yellow: #F3B438;
  --mclu-yellow-rgba: rgba(243, 180, 56, 0.75);;
  --mclu-red: #D94836;
  --mclu-blue: #3f7ce9;
  --mclu-green:   #276652;
  --mclu-grey: #455A64;

  --mclu-yellow-mobile: #F9BE4C;
  --mclu-red-mobile: #e65947;
  --mclu-blue-mobile: #588ff3;
  --mclu-green-mobile:   #458f77;


  --mclu-green-valid:   #6AAE86;

  --mclu-pro-yellow: #F16823;

  --mclu-disabled: #B5BEC2;

  --mclu-pro-blue: #0063A6;

  --mclu-2-orange: #F67C0B;

  --mclu-green-light: #dfe8e5;

  --mclu-green-medium: #40816c;

  --mclu-blue-light: #e2ebfc;

  --mclu-green-middle: #39856d;

  --mclu-yellow-light: #ffebc3;

  --mclu-yellow-middle: #f5be51;

  --mclu-grey-admin: #494949;

  --mclu-background-light: #FEF7EB;


  /*====== FIN NOUVEAU DESIGN ======*/





  /** Core **/
  --core-color-grey-dark: #455A64;
  --core-color-grey-medium: #8F9CA2;
  --core-color-grey-light: #DEE6EA;
  --core-color-white: #FFFFFF;
  --core-color-blur: #FFFFFF33;
  --core-color-blue: #4D81D5;
  --core-color-blue-gradient: linear-gradient(90deg, #4D81D5 0%, #1656B5 100%);
  --core-color-green: #00A887;
  --core-color-red-dark: #C77256;
  --core-color-red-light: #E7B4A3;
  --core-color-portail-foncier: #448273;

  /** Button **/
  --button-color-primary: linear-gradient(134.71deg, #E56E2E 19.48%, #BC602E 100%);
  --button-color-primary-hover: #C65518;
  --button-color-primary-disabled: #E89B72;
  --button-color-secondary: #4D81D5;
  --button-color-secondary-hover: #23539A;
  --button-color-secondary-disabled: #BAD0F2;

  /** Background Color **/
  --background-color-light: #F1E9DD;
  --background-color-grey: #E4E4E4;
  --background-color-error: #E7BEB1;
  --background-color-astuce-gradient: linear-gradient(83.97deg, #60AE8F -1.11%, #41888B 126.03%);
  --background-color-green-light: #D6FFEE;
  --background-color-blue-medium: #BAD0F2;
  --background-color-blue-light: #DCEAFF;
  --background-color-pending: #E0E7EA;
  --background-color-grey-step: #F7FAFD;

  /** Typography **/
  --typo-color-light: #F1E9DD;
  --typo-color-grey-dark: #515B60;
  --typo-color-grey-light: #8F9CA2;
  --typo-color-white: #FFFFFF;
  --typo-color-red: #C77256;
  --typo-color-espace-connecte: #448273;
  --typo-color-blue: #4D81D5;
  --typo-color-green: #00A887;

  /** Functionality **/
  --success-color: #00A887;
  --info-color: #4978BE;
  --warning-color: #ECC35A;
  --alert-color: #E3686B;

  --wireframes-color-grey-dark: #424242;
  --wireframes-color-grey-medium: #9E9E9E;
  --wireframes-color-white: #FFFFFF;


  /** Fonts principale **/
  --ion-font-family: "Montserrat";


  /** primary **/
  --ion-color-primary: var(--button-color-primary);
  --ion-color-primary-rgb: 229,110,46;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #0c161b;
  --ion-color-primary-tint: var(--button-color-primary-hover);

  /** secondary **/
  --ion-color-secondary: var(--button-color-secondary);
  --ion-color-secondary-rgb: 77, 129, 213;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: var(--button-color-secondary-hover);

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Toolbar */
  --ion-toolbar-color: #fff;
  --ion-toolbar-background: #448273;

}

/*
@media (prefers-color-scheme: dark) {

  body {
    --ion-color-primary: var(--button-color-primary);
    --ion-color-primary-rgb: 229,110,46;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #0c161b;
    --ion-color-primary-tint: var(--button-color-primary-hover);

    --ion-color-secondary: var(--button-color-secondary);
    --ion-color-secondary-rgb: 77,129,213;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: var(--button-color-secondary-hover);

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }


  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
*/
