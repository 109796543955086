/* FICHIER SOURCE OPENLAYERS, NE PAS MODIFIER */
  .ol-full-screen{
    right: 0px !important;
    top: 0px !important;
    background-color: white !important;
    padding: 0px !important;
    border-radius: 8px 0px 0px 8px !important;
  }

  .ol-control button{
    color: black !important;
    background-color: white!important;
    width: 54px !important;
    height: 44px !important;
    margin: 0px !important;
    font-size: 27px !important;
    border-style: solid !important;
    border-color: #e1e1e1 !important;
    border-width: 1px !important;
    border-radius: 0px 0px 0px 8px !important;
  }

  .ol-control button:hover{
    background-color: #fdf9f7 !important;
  }

  .ol-scale-line {
    right: 71px !important;
    bottom: 14px !important;
    left: auto !important;
    background: rgba(217, 72, 54, 0.75) !important;
  }

  .ol-scale-line-inner{
    color: white !important;
    font-weight: bold;
    border-color: white !important;
    font-family: Montserrat, sans-serif;
    font-size: 11px;
  }
  .ol-attribution{
    display: none !important;
  }


  .isMobile {
    .ol-scale-line {
      right: initial !important;
      left: 12px !important;
    }
  }

